import cn from 'classnames';
import ClientLogoMarquee from '@components/Marquee/ClientLogoMarquee';
import Image from '@components/Image';

import * as styles from './DynamicClientLogoMarquee.module.css';

const DynamicClientLogoMarquee = ({ clientLogos }) => {
  return (
    <div className={cn(styles.dynamicClientLogoMarquee)}>
      <ClientLogoMarquee
        clientLogos={clientLogos}
        repeatCount={3}
        imgClassName={cn(styles.clientLogo)}
        marqueeClassName={cn(styles.clientLogosMarquee)}
      />
      <div className={cn(styles.clientLogosStatic)}>
        {clientLogos.map((logo, index) => (
          <div key={index} className={cn(styles.clientLogo)}>
            <Image
              src={logo.src}
              alt={logo.alt}
              width={logo.width}
              height={logo.height}
              placeholder="blur"
              blurDataURL={logo.lqip}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicClientLogoMarquee;

import SanityImage from '@components/SanityImage';
import { isSafari } from '@util/browser';
import cn from 'classnames';

import * as styles from './ClientLogos.module.css';

const ClientLogos = ({ clientLogos, imgClassName }) => {
  const MAX_WIDTH = 130;
  const MAX_HEIGHT = 35;

  return (
    <>
      {clientLogos.map((logo, index) => {
        return (
          <SanityImage
            priority={isSafari()}
            key={index}
            asset={logo.image.asset}
            alt={logo.alt}
            className={cn(styles.logo, imgClassName)}
            placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
            maxWidth={MAX_WIDTH}
            maxHeight={MAX_HEIGHT}
          />
        );
      })}
    </>
  );
};

export default ClientLogos;

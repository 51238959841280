import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { PortableText } from '@portabletext/react';

import { useInView } from 'framer-motion';

import * as styles from './TextRenderer.module.css';

const portableTextComponents = {
  marks: {
    link: ({ children, value }) => {
      let href = '';
      let openInNewTab = '_self';

      if (value.link_type === 'email') {
        href = `mailto:${value?.email}`;
      } else if (value.link_type === 'phone') {
        href = `tel:${value?.phone}`;
      } else if (value.link_type === 'link') {
        openInNewTab = value?.new_tab ? '_blank' : '_self';
        href = value?.url;
      }

      return (
        <a href={href} target={openInNewTab ? '_blank' : '_self'}>
          {children}
        </a>
      );
    },
  },
};

const RichTextRenderer = ({
  text,
  className,
  as = 'div',
  strongAsGradient = true,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.9, once: true });
  const Tag = as;

  return (
    <Tag
      ref={ref}
      className={cn(className, styles.root, {
        [styles.inView]: inView,
        [styles.strongAsGradient]: strongAsGradient,
      })}
    >
      <PortableText value={text} components={portableTextComponents} />
    </Tag>
  );
};

RichTextRenderer.propTypes = {
  text: PropTypes.array.isRequired,
  className: PropTypes.string,
  as: PropTypes.string,
};

export default RichTextRenderer;

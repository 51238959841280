import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@components/Button';
import interactionHooks from '@util/interactions';

import { dialogMap } from '@dialogs';

import * as styles from './Actions.module.css';

const Action = ({
  label,
  type,
  href,
  dialog,
  interaction: _interaction,
  isStartFreeTrial,
  trackingScope,
  theme = 'light',
  colorScheme,
  size,
  minWidth,
  withArrow,
}) => {
  const interaction = _interaction ? interactionHooks[_interaction]() : null;

  const Dialog = dialog ? dialogMap[dialog] : null;

  const element = (
    <div
      className={cn(styles.action, minWidth && styles.actionMinWidth)}
      style={{ '--button-min-width': minWidth }}
    >
      <Button
        fullWidth={!!minWidth}
        variant={type ?? 'primary'}
        href={Dialog ? null : href}
        onClick={Dialog ? null : interaction}
        trackingScope={trackingScope}
        colorScheme={
          colorScheme ??
          (theme === 'light' || theme === 'white' ? 'accent' : 'light')
        }
        withArrow={withArrow}
        size={size}
        isStartFreeTrial={isStartFreeTrial}
      >
        {label}
      </Button>
    </div>
  );

  return Dialog ? <Dialog>{element}</Dialog> : element;
};

const Actions = ({
  actions,
  className,
  theme,
  trackingScope,
  overwrites = {},
}) => {
  return (
    <div className={cn(styles.root, className)}>
      {actions.map((action, index) => (
        <Action
          key={index}
          trackingScope={trackingScope}
          theme={theme}
          {...action}
          {...overwrites}
        />
      ))}
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'link']),
      href: PropTypes.string,
      interaction: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.string,
  className: PropTypes.string,
  trackingScope: PropTypes.string,
};

export default Actions;

import SanityImage from '@components/SanityImage';
import Marquee from './Marquee';
import { isSafari } from '@util/browser';
import cn from 'classnames';

import * as styles from './ClientLogoMarquee.module.css';

const ClientLogoMarquee = ({
  clientLogos,
  speed = 0.1,
  repeatCount = 2,
  imgClassName,
  marqueeClassName,
}) => {
  const MAX_WIDTH = 130;
  const MAX_HEIGHT = 35;

  return (
    <Marquee
      speed={speed}
      repeatCount={repeatCount}
      marqueeClassName={marqueeClassName}
      style={{
        '--marquee-item-gap': 'var(--space-xl)',
      }}
    >
      {clientLogos.map((logo, index) => {
        return (
          <SanityImage
            priority={isSafari()}
            key={index}
            asset={logo.image.asset}
            alt={logo.alt}
            className={cn(styles.logo, imgClassName)}
            placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
            maxWidth={MAX_WIDTH}
            maxHeight={MAX_HEIGHT}
          />
        );
      })}
    </Marquee>
  );
};

export default ClientLogoMarquee;
